<template>
	<div>
		<section class="happiness">
			<div class="player">
				<!-- <div id="framePlayer" ref="framePlayer"></div> -->
				<img :src="baseImg+'/player/qianq_00026.png'" alt>
			</div>
			<div class="con">
				<img class="happy" :src="baseImg+'happiness.png'" alt>
				<div class="line"></div>
				<div class="name">光大银行小米联名信用卡</div>
				<div class="line"></div>
				<div class="apply-warp">
					<div class="apply">立即申请</div>
					<div class="code-con">
						<div class="code-desc">扫码办卡</div>
						<img class="scan_code" :src="baseImg+'ceb-code.jpeg'" alt>
					</div>
				</div>
			</div>
		</section>
		<section class="card">
			<div class="text">
				<img class="title" :src="baseImg+'gradient.png'" alt>
				<img class="type" :src="baseImg+'blue.png'" alt>
			</div>
			<div class="red-card">
				<img :src="baseImg+'bule-card2.png'" alt>
			</div>
		</section>
		<section class="tv-rights">
			<div class="tv-package">
				<img class="tv-title" :src="baseImg+'tv-package.png'" alt="">
				<img class="tv-img" :src="baseImg+'tv.png'" alt="">
			</div>
			<div class="gift">
				<div class="title">
					<i></i>
					<span>新户首刷礼、月度活跃礼，以下权益任选</span>
					<i></i>
				</div>
				<ul>
					<li><img :src="baseImg+'mi-tv-member.png'" alt="">电视影视会员</li>
					<li><img :src="baseImg+'children-tv-member.png'" alt="">电视儿童会员</li>
					<li><img :src="baseImg+'buy-tv-300.png'" alt="">购电视立减300</li>
					<li><img :src="baseImg+'buy-tv-300.png'" alt="">200元电视通用券</li>
				</ul>
			</div>
		</section>
		<section class="discount">
			<div class="con">
				<ul>
					<li><img :src="baseImg+'annual-fee.png'" alt="">免年费</li>
					<li><img :src="baseImg+'highSpeedRails.png'" alt="">高铁贵宾厅</li>
					<li><img :src="baseImg+'airportCIP.png'" alt="">机场CIP</li>
					<li><img :src="baseImg+'watch-movie.png'" alt="">10元看大片</li>
					<li><img :src="baseImg+'sing-song.png'" alt="">10元唱HIGH歌</li>
					<li><img :src="baseImg+'enjoy-food.png'" alt="">10元享美食</li>
				</ul>
				<div class="fun">
					<img class="title" :src="baseImg+'many-offers.png'" alt="">
					<img class="song" :src="baseImg+'mike.png'" alt="">
				</div>
			</div>
			<div class="apply-warp">
				<div class="apply">立即申请</div>
				<div class="code-con">
					<div class="code-desc">扫码办卡</div>
					<img class="scan_code" :src="baseImg+'ceb-code.jpeg'" alt>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
import { quickCustomPropToTsmWebsite } from '@/common/sensorsSdk'
export default {
	name: "Ceb",
	data() {
		return {
			baseImg: "https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/ceb/",
			// playerImgList: []
		};
	},
	mounted() {
		quickCustomPropToTsmWebsite({websiteName: '光大小米联名信用卡'})
	},
};
</script>

<style scoped lang="less">
.hide {
	display: none;
}
.happiness {
	background: linear-gradient(128deg, rgba(255, 123, 169, 1) 0%, rgba(56, 102, 249, 1) 100%);
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;
	padding: 6vw 5vw;
	.player {
		img {
			width: 35vw;
			margin-left: 7vw;
		}
	}
	.con {
		width: 50vw;
		text-align: center;
		.happy {
			width: 29vw;
		}
		.line {
			width: 25vw;
			height: 1px;
			background: -webkit-linear-gradient(left,rgba(255, 255, 255, 0),#5dddd3, rgba(255, 255, 255, 0));
			background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
			margin: 0 auto;
		}
		.name {
			width: 30vw;
			margin: 0 auto;
			color: #fff;
			font-size: 1.1vw;
			font-family: PingFangSC-Light;
			letter-spacing: 7px;
			line-height: 2.4vw;
		}
	}
}
.card {
	display: flex;
	align-items: center;
	padding: 7vw 5vw;
	.text{
		width: 50%;
		img{
			margin: 2vw auto;
			display: block;
		}
		.title{
			width: 27vw;
		}
		.type{
			width: 16vw;
		}
	}
	.red-card{
		width: 50%;
		text-align: center;
		img{
			width: 30vw;
		}
	}
}
.tv-rights{
	display: flex;
	align-items: center;
	background: linear-gradient(128deg,rgba(255,123,169,1) 0%,rgba(56,102,249,1) 100%);
	padding: 7vw 5vw;
	align-items: flex-start;
	.tv-package{
		width: 50%;
		.tv-title{
			display: block;
			margin: 0 auto 3vw;
			width: 22vw;
		}
		.tv-img{
			display: block;
			margin: 0 auto;
			width: 35vw;
		}
	}
	.gift{
		width: 50%;
		color: #fff;
		margin-top: .7vw;
		.title{
			text-align: center;
			i{
				width: 4vw;
				height: .1vw;
				margin: 0vw .5vw .4vw;
				background: linear-gradient(90deg,rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				display: inline-block;
				&:last-child{
					background: linear-gradient(90deg,rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
				}
			}
			span{
				font-size: 1.5vw;
				font-weight: bold;
			}
		}
		ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 85%;
    margin: 3vw auto;
			li{
    text-align: center;
    font-size: 1vw;
    width: 25%;
				img{
    display: block;
    margin: 3vw auto 0.5vw;
    width: 6.5vw;
				}
			}
		}
	}
}
.discount{
	display: flex;
	flex-direction: column;
    padding: 7vw 6vw 4vw;
	.con{
		display: flex;
		ul{
			display: flex;
			flex-wrap: wrap;
			width: 50%;
			padding: 0 4vw;
			li{
				text-align: center;
				font-size: 1vw;
				width: 33.3%;
				img{
					display: block;
					margin: 0 auto .5vw;
					width: 6vw;
				}
			}
		}
		.fun{
			width: 50%;
			.title{
				width: 19vw;
				display: block;
				margin: 0 auto 2vw;
			}
			.song{
				width: 31vw;
				display: block;
				margin: 0 auto;
			}
		}
	}
}
.apply-warp {
	width: 13vw;
	height: 4vw;
	margin: 1vw auto;
	cursor: pointer;
	&:hover {
		.code-con {
			display: block;
		}
	}
	.apply {
		width: 13vw;
		line-height: 4vw;
		background: url(https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/ceb/apply.png) no-repeat center center;
		background-size: 14vw 4.3vw;
		text-align: center;
		color: #656363;
		font-size: 1vw;
		letter-spacing: 0.1vw;
		cursor: pointer;
	}
	.code-con {
		display: none;
		text-align: center;
		background: url(https://cnbj1.fds.api.xiaomi.com/tsm-home-front/ceb/code-bg.png) no-repeat top center;
		background-size: 13vw;
		width: 13vw;
		height: 15vw;
		padding: 1.3vw;
		margin: 0 auto;
		.code-desc {
			color: #fff;
			font-size: 1vw;
			font-weight: 500;
			margin-bottom: 8px;
		}
		.scan_code {
			width: 10vw;
		}
	}
}
</style>
